exports.ReflectRoMenuToHeader = function ReflectRoMenuToHeader() {
  let mainMenu = {};
  let cildsubMenu = [];
  $(".menu-list")
    .find("a")
    .click(function () {
      let baseUrl = $("base")
        .attr("href")
        .substring(0, $("base").attr("href").length - 1);
      let that = $(this).closest("li.menu-item");
      if (
        $(this).attr("href").replace(baseUrl, "") != null &&
        $(this).data("parent") != "parent"
      ) {
        mainMenu = {
          name: $(that).find("a .flux-mtext").html(),
          url: $(this).find("a").attr("href"),
          subMenu: [],
        };
        if ($(that).find("ul.subMenu-ul").length > 0) {
          $($(that).find("li.subMenu-li")).each(function () {
            let subMenu = {};
            cildsubMenu = [];

            if ($(this).find("ul.child-submenu li").length > 0) {
              $($(this).find("ul.child-submenu li")).each(function () {
                cildsubMenu.push({
                  name: $(this).find("a .flux-mtext").html(),
                  url: $(this).find("a").attr("href").replace(baseUrl, ""),
                });
              });
            }
            subMenu = {
              name: $(this).find("a .flux-mtext").html(),
              url: $(this).find("a").attr("href").replace(baseUrl, ""),
              hidden: $(this).find("a").data("hidden"),
              cildsubMenu,
            };
            mainMenu.subMenu.push(subMenu);
          });
        }
        localStorage.setItem("PageTitle", mainMenu.name);
        localStorage.setItem("mainMenu", JSON.stringify(mainMenu.subMenu));
        let evt = new CustomEvent("SideMenuChanged", { detail: [mainMenu] });
        window.dispatchEvent(evt);
        setTimeout(function () {
          $(
            '[href="' +
              window.location.href.replace(window.location.origin, "") +
              '"].top-nav-link',
          ).addClass("active");
          $(
            '[href="' +
              window.location.href.replace(window.location.origin, "") +
              '"].top-nav-link',
          )
            .parent("li")
            .parent("ul")
            .parent("li")
            .addClass("active");
          $(".top-nav-link").click(function () {
            $(".top-nav-link").removeClass("active");
            $(
              '[href="' +
                window.location.href.replace(window.location.origin, "") +
                '"].top-nav-link',
            ).addClass("active");
            $(
              '[href="' +
                window.location.href.replace(window.location.origin, "") +
                '"].top-nav-link',
            )
              .parent("li")
              .parent("ul")
              .parent("li")
              .addClass("active");
          });
        }, 200);
      }
    });
  setTimeout(function () {
    $(
      '[href="' +
        window.location.href.replace(window.location.origin, "") +
        '"]',
    ).trigger("click");
  }, 300);
};
